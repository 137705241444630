














































































































































































































































































































import Vue, { PropOptions, PropType } from "vue";
import { FilterOption, GroupedMatches, SelectItem } from "@/types";
import MatchExtModel from "../../Classes/MatchExtModel";
import MatchBlock from "../MatchBlock/index.vue";
import { mdiClose, mdiTune } from "@mdi/js";
import { CompetitorModel, MatchModel } from "@memberpoint/ba-result-components";

interface MatchFilters {
  round: FilterOption<number | null>;
  competitor: FilterOption<string | null>;
  inPlay: FilterOption<boolean | null>;
  hideCompleted: FilterOption<boolean | null>;
  hideByes: FilterOption<boolean | null>;
  onlyForCompetingMatches: FilterOption<boolean | null>;
  onlyForMarkingMatches: FilterOption<boolean | null>;
}

type MatchFilterKeys = keyof MatchFilters;

type MatchFilterForm = {
  [K in keyof MatchFilters]: MatchFilters[K]["value"];
};

const setFilterObject = <Key extends keyof MatchFilters>(
  obj: MatchFilters,
  key: Key,
  value: MatchFilters[Key]
) => {
  obj[key] = value;
};

const hasProp = Object.prototype.hasOwnProperty;

export default Vue.extend({
  name: "MatchTable",

  components: { MatchBlock },

  props: {
    matches: {
      type: Array as PropType<MatchModel[]>,
      required: false,
      default() {
        return [];
      },
    } as PropOptions<MatchModel[]>,

    groupedMatches: {
      type: Object as PropType<GroupedMatches>,
      required: false,
      default() {
        return {};
      },
    } as PropOptions<GroupedMatches>,

    groupIcon: {
      type: String,
      required: false,
      default: null,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    error: {
      type: String,
      required: false,
      default: null,
    },

    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },

    filters: {
      type: Object as PropType<Partial<MatchFilterForm>>,
      required: false,
      default: null,
    } as PropOptions<Partial<MatchFilterForm>>,

    useVirtualScroller: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    const availableFilters: MatchFilters = {
      round: {
        label: "Round",
        value: null,
      },
      competitor: {
        label: "Competitor",
        value: null,
      },
      inPlay: {
        label: "In Play",
        value: null,
      },
      hideCompleted: {
        label: "Hide completed matches",
        value: true,
      },
      hideByes: {
        label: "Hide Byes",
        value: true,
      },
      onlyForCompetingMatches: {
        label: "Only competing matches",
        value: null,
      },
      onlyForMarkingMatches: {
        label: "Only marking matches",
        value: null,
      },
    };

    let iFilters: Partial<MatchFilters> = {};

    if (this.filters && typeof this.filters === "object") {
      for (const key in this.filters) {
        if (hasProp.call(availableFilters, key)) {
          setFilterObject(
            iFilters as MatchFilters,
            key as MatchFilterKeys,
            availableFilters[key as MatchFilterKeys]
          );

          (iFilters as MatchFilters)[key as MatchFilterKeys].value = this
            .filters[
            key as MatchFilterKeys
          ] as MatchFilters[keyof MatchFilters]["value"];
        }
      }
    } else {
      iFilters = availableFilters;
    }

    const filterForm: Record<string, unknown> = {};

    for (const key in iFilters) {
      if (hasProp.call(iFilters, key)) {
        filterForm[key] = iFilters[key as MatchFilterKeys]?.value;
      }
    }

    return {
      loadingMatches: false,
      filterIcon: mdiTune,
      closeIcon: mdiClose,
      showFilterDialog: false,
      iFilters,
      filterForm: filterForm as MatchFilterForm,
      virtualScrollHeight: "500px",
      filterStateChecksum: "",
      matchVisibility: {} as Record<string, boolean>,
    };
  },

  computed: {
    /**
     * Returns TRUE to display loading matches skeleton.
     *
     * @return {boolean}
     */
    showLoading(): boolean {
      return this.loading || this.loadingMatches;
    },

    /**
     * Returns an array of round options for the round filter.
     *
     * @return {Array<SelectItem>}
     */
    roundOptions(): Array<SelectItem<number>> {
      const roundNumbers: Record<string, string> = {};

      for (const group in this.groupedMatches) {
        if (hasProp.call(this.groupedMatches, group)) {
          this.groupedMatches[group].matches.forEach((match: MatchModel) => {
            let roundName;

            if (
              match instanceof MatchExtModel &&
              typeof match.roundName === "string"
            ) {
              roundName = match.roundName;
            } else {
              roundName = `Round ${match.round}`;
            }

            roundNumbers[match.round] = roundName;
          });
        }
      }

      return Object.keys(roundNumbers).map((roundNumber: string) => {
        return {
          text: roundNumbers[roundNumber],
          value: Number(roundNumber),
        };
      });
    },

    /**
     * Returns an array of competitor options for the competitor filter.
     *
     * @return {Array<SelectItem>}
     */
    competitorOptions(): Array<SelectItem<string>> {
      const competitorIdMapping: Record<string, string> = {};

      for (const group in this.groupedMatches) {
        if (hasProp.call(this.groupedMatches, group)) {
          this.groupedMatches[group].matches.forEach((match: MatchModel) => {
            if (
              match.competitorOne instanceof CompetitorModel &&
              !match.competitorOne.isBye() &&
              !match.competitorOne.isTbc()
            ) {
              competitorIdMapping[
                match.competitorOne.id
              ] = match.competitorOne.getName();
            }

            if (
              match.competitorTwo instanceof CompetitorModel &&
              !match.competitorTwo.isBye() &&
              !match.competitorTwo.isTbc()
            ) {
              competitorIdMapping[
                match.competitorTwo.id
              ] = match.competitorTwo.getName();
            }
          });
        }
      }

      return (
        Object.keys(competitorIdMapping)
          .map((competitorId: string) => {
            return {
              text: competitorIdMapping[competitorId],
              value: competitorId,
            };
          })

          // Sort by competitor name in ASC
          .sort((a, b) => {
            if (a.text === b.text) {
              return 0;
            }

            return a.text > b.text ? 1 : -1;
          })
      );
    },

    /**
     * Returns the list of items for display.
     * An item can be a string to display as a sub-heading or a MatchModel
     * as a match block component.
     *
     * @return {Array<MatchModel | string>}
     */
    filteredListItems(): Array<MatchModel | string> {
      const items: Array<MatchModel | string> = [];

      for (const group in this.groupedMatches) {
        if (Object.prototype.hasOwnProperty.call(this.groupedMatches, group)) {
          const groupMatches = this.groupedMatches[group];

          const filteredMatches = groupMatches.matches.filter(
            (match: MatchModel) => {
              return (
                this.matchVisibility[match.id] ||
                !Object.prototype.hasOwnProperty.call(
                  this.matchVisibility,
                  match.id
                )
              );
            }
          );

          if (filteredMatches.length > 0) {
            // Add the item to represent the sub title which should the group name.
            if (groupMatches.groupName) {
              items.push(groupMatches.groupName);
            }

            items.push(...filteredMatches);
          }
        }
      }

      return items;
    },

    /**
     * Returns TRUE if there active filters; otherwise FALSE.
     *
     * @return {boolean}
     */
    hasActiveFilters(): boolean {
      for (const key in this.iFilters) {
        if (hasProp.call(this.iFilters, key)) {
          if (this.iFilters[key as MatchFilterKeys]?.value !== null) {
            return true;
          }
        }
      }

      return false;
    },

    /**
     * Returns TRUE if there are matches from the given grouped matches.
     *
     * @return {boolean}
     */
    hasMatches(): boolean {
      for (const group in this.groupedMatches) {
        if (
          hasProp.call(this.groupedMatches, group) &&
          this.groupedMatches[group].matches.length > 0
        ) {
          return true;
        }
      }

      return false;
    },

    /**
     * Returns TRUE if we can filter matches.
     *
     * @return {boolean}
     */
    canFilter(): boolean {
      return Object.keys(this.iFilters).length > 0;
    },
  },

  watch: {
    groupedMatches: {
      immediate: true,
      deep: true,
      handler() {
        this.updateMatches();
      },
    },
  },

  updated() {
    if (this.useVirtualScroller) {
      this.$nextTick(() => {
        this._updateVirtualScrollHeight();
      });
    }
  },

  created() {
    this.filterStateChecksum = this._createFilterChecksum();
  },

  methods: {
    setInternalFilter<Key extends keyof MatchFilters>(
      key: Key,
      value: MatchFilters[Key]
    ): void {
      this.iFilters[key] = value;
    },

    /**
     * Returns the label for the filter if there is one set;
     * otherwise return the key of the filter.
     *
     * @param {string} key
     * @return {string}
     */
    getFilterLabel(key: MatchFilterKeys): string {
      let label = key as string;

      if (hasProp.call(this.iFilters, key) && this.iFilters[key]?.label) {
        label = this.iFilters[key]?.label as string;
      }

      return label;
    },

    /**
     * Returns the value of the filter that is readable for display.
     *
     * @param {string} key
     * @return {*|null}
     */
    getReadableFilterValue(key: MatchFilterKeys): unknown {
      let value = null;

      if (
        key === "hideCompleted" ||
        key === "hideByes" ||
        key === "onlyForMarkingMatches" ||
        key === "onlyForCompetingMatches" ||
        key === "inPlay"
      ) {
        return "";
      }

      // For "competitor" filter we can get the label from the
      // list of competitor options.
      if (key === "competitor" && this.iFilters[key]?.value !== null) {
        const index = this.competitorOptions.findIndex(
          (option) => option.value === this.iFilters[key]?.value
        );

        if (index !== -1) {
          return this.competitorOptions[index].text;
        }
      } else if (key === "round" && this.iFilters[key]?.value !== null) {
        // For "round" filter we can get the label from the
        // list of round options.
        const index = this.roundOptions.findIndex(
          (option) => option.value === this.iFilters[key]?.value
        );

        if (index !== -1) {
          return this.roundOptions[index].text;
        }
      }

      if (
        Object.prototype.hasOwnProperty.call(this.iFilters, key) &&
        this.iFilters[key]?.value !== null
      ) {
        value = this.iFilters[key]?.value;
      }

      return value;
    },

    /**
     * Close the filter dialog.
     */
    closeFilterDialog(): void {
      this.showFilterDialog = false;
    },

    /**
     * Clear all active filters.
     *
     * @param {boolean} updateMatches
     */
    clearFilters(updateMatches: boolean): void {
      for (const key in this.iFilters) {
        if (hasProp.call(this.iFilters, key)) {
          (this.iFilters as MatchFilters)[key as MatchFilterKeys].value = null;
        }
      }

      for (const key in this.filterForm) {
        if (hasProp.call(this.filterForm, key)) {
          this.filterForm[key as MatchFilterKeys] = null;
        }
      }

      this.filterStateChecksum = this._createFilterChecksum();

      if (updateMatches) {
        this.updateMatches(true);
      }
    },

    /**
     * Remove a selected filter.
     *
     * @param {string} key
     */
    removeFilter(key: MatchFilterKeys): void {
      if (hasProp.call(this.iFilters, key)) {
        (this.iFilters as MatchFilters)[key as MatchFilterKeys].value = null;
        this.filterForm[key] = null;

        this.filterStateChecksum = this._createFilterChecksum();

        this.updateMatches(true);
      }
    },

    /**
     * Returns TRUE if the filters have changed; otherwise FALSE.
     *
     * @return {boolean}
     */
    hasChangedFilter(): boolean {
      return this.filterStateChecksum !== this._createFilterChecksum();
    },

    /**
     * Update matches by applying filters.
     */
    updateMatches(loading = false): void {
      if (!this.canFilter) {
        return;
      }

      if (loading) {
        this.loadingMatches = true;

        setTimeout(() => {
          this._internalUpdateGroupedMatches();

          this.loadingMatches = false;
        }, 500);
      } else {
        this._internalUpdateGroupedMatches();
      }
    },

    /**
     * Handle the filter form submit.
     */
    submitFilter(): void {
      // Note: The text field returns empty string for no value
      // so we should set the filter as inactive if its empty.

      if (this.iFilters.round) {
        this.iFilters.round.value = this.filterForm.round;
      }

      if (this.iFilters.competitor) {
        this.iFilters.competitor.value = this.filterForm.competitor;
      }

      // "FALSE" should be set to NULL so it is not active in the filter
      // because we see active state as only "TRUE" in a toggle switches.
      if (this.iFilters.hideCompleted) {
        if (!this.filterForm.hideCompleted) {
          this.iFilters.hideCompleted.value = null;
        } else {
          this.iFilters.hideCompleted.value = true;
        }
      }

      if (this.iFilters.hideByes) {
        if (!this.filterForm.hideByes) {
          this.iFilters.hideByes.value = null;
        } else {
          this.iFilters.hideByes.value = true;
        }
      }

      if (this.iFilters.onlyForCompetingMatches) {
        if (!this.filterForm.onlyForCompetingMatches) {
          this.iFilters.onlyForCompetingMatches.value = null;
        } else {
          this.iFilters.onlyForCompetingMatches.value = true;
        }
      }

      if (this.iFilters.onlyForMarkingMatches) {
        if (!this.filterForm.onlyForMarkingMatches) {
          this.iFilters.onlyForMarkingMatches.value = null;
        } else {
          this.iFilters.onlyForMarkingMatches.value = true;
        }
      }

      if (this.iFilters.inPlay) {
        if (!this.filterForm.inPlay) {
          this.iFilters.inPlay.value = null;
        } else {
          this.iFilters.inPlay.value = true;
        }
      }

      this.closeFilterDialog();

      // Only update match listing if the filters have been changed.
      if (this.hasChangedFilter()) {
        this.updateMatches(true);

        // Renew the filter checksum.
        this.filterStateChecksum = this._createFilterChecksum();
      }
    },

    /**
     * Returns TRUE if the match is a BYE.
     *
     * @return {boolean}
     */
    isMatchBye(match: MatchModel): boolean {
      return (
        (match.competitorOne instanceof CompetitorModel &&
          match.competitorOne.isBye()) ||
        (match.competitorTwo instanceof CompetitorModel &&
          match.competitorTwo.isBye())
      );
    },

    /**
     * Handle the "click" event when clicking on a match.
     *
     * @param {MatchModel} match
     */
    onMatchClick(match: MatchModel): void {
      this.$emit("match-selected", match);
    },

    /**
     * Handle the "click" to open the filter dialog.
     */
    onFilter(): void {
      this.showFilterDialog = true;
    },

    /**
     * Returns a simple checksum for the current filters.
     * This is mainly used in checking if the filters have been altered.
     *
     * @return {string}
     * @private
     */
    _createFilterChecksum(): string {
      let checksum = "";

      for (const key in this.iFilters) {
        if (hasProp.call(this.iFilters, key)) {
          const value = this.iFilters[key as MatchFilterKeys]?.value;

          checksum += `${key}:${value},`;
        }
      }

      return checksum.slice(0, -1);
    },

    /**
     * Update grouped matches by applying filters.
     *
     * @private
     */
    _internalUpdateGroupedMatches(): void {
      for (const round in this.groupedMatches) {
        if (Object.prototype.hasOwnProperty.call(this.groupedMatches, round)) {
          this._internalUpdateMatches(this.groupedMatches[round].matches);
        }
      }
    },

    /**
     * Update matches by applying filters.
     *
     * @param {MatchModel[]} matches
     * @private
     */
    _internalUpdateMatches(matches: MatchModel[]): void {
      matches.forEach((match: MatchModel) => {
        let show = true;

        // Apply filters.
        if (
          this.iFilters.round &&
          this.iFilters.round.value &&
          match.round !== Number(this.iFilters.round.value)
        ) {
          show = false;
        }

        if (
          this.iFilters.competitor &&
          this.iFilters.competitor.value !== null &&
          match.competitorOne.id !== this.iFilters.competitor.value &&
          match.competitorTwo.id !== this.iFilters.competitor.value
        ) {
          show = false;
        }

        // If "inPlay" filter is ON then only include matches that have dates
        // within yesterday and tomorrow.
        if (this.iFilters.inPlay && this.iFilters.inPlay.value) {
          const matchDate = new Date(match.matchTimeUtc);

          if (!isNaN(matchDate.getTime())) {
            let yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            let tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);

            if (
              matchDate.getTime() < yesterday.getTime() ||
              matchDate.getTime() > tomorrow.getTime()
            ) {
              show = false;
            }
          }
        }

        if (
          this.iFilters.hideCompleted &&
          this.iFilters.hideCompleted.value &&
          match.result &&
          match.result.isFinalized === true
        ) {
          show = false;
        }

        if (
          this.iFilters.hideByes &&
          this.iFilters.hideByes.value &&
          this.isMatchBye(match)
        ) {
          show = false;
        }

        const filteredScorableContexts: string[] = [];

        // Filter by "competitor" scorable context
        if (
          this.iFilters.onlyForCompetingMatches &&
          this.iFilters.onlyForCompetingMatches.value
        ) {
          filteredScorableContexts.push("competitor");
        }

        // Filter by "marker" scorable context
        if (
          this.iFilters.onlyForMarkingMatches &&
          this.iFilters.onlyForMarkingMatches.value
        ) {
          filteredScorableContexts.push("marker");
        }

        if (filteredScorableContexts.length > 0) {
          const scorableContexts =
            match instanceof MatchExtModel ? match.scorableContexts : [];

          // Since there is no scorable contexts we should just hide the match
          // to save ourselves from looping.
          if (scorableContexts.length === 0) {
            show = false;
          } else {
            for (let i = 0; i < filteredScorableContexts.length; i++) {
              const filteredScorableContext = filteredScorableContexts[i];

              if (scorableContexts.indexOf(filteredScorableContext) === -1) {
                show = false;

                break;
              }
            }
          }
        }

        this.$set(this.matchVisibility, match.id, show);
      });
    },

    /**
     * Update the height of the virtual scroller based on the
     * position of the matches listing.
     *
     * @private
     */
    _updateVirtualScrollHeight(): void {
      if (this.$refs.matchTable) {
        const top = (this.$refs
          .matchTable as HTMLElement).getBoundingClientRect().top;

        this.virtualScrollHeight = `calc(100vh - ${top}px)`;
      }
    },
  },
});
