



























































import Vue from "vue";
import {
  CompetitionModel,
  MatchModel,
} from "@memberpoint/ba-result-components";
import SavedMatches from "@/services/SavedMatches";
import InfoBlock from "@/components/InfoBlock/index.vue";
import { GroupedMatches } from "@/types";
import MatchTable from "@/components/MatchTable/index.vue";
import { mdiCalendarMonthOutline } from "@mdi/js";

const UNKNOWN_MATCH_GROUP = "__UNKNOWN__";

export default Vue.extend({
  name: "MyScoredMatches",
  components: { MatchTable, InfoBlock },
  props: {
    limit: {
      type: Number,
      required: false,
      default: 0,
    },
    matchFilters: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      matches: [] as MatchModel[],
      calendarIcon: mdiCalendarMonthOutline,
    };
  },
  computed: {
    /**
     * Get a filtered collection of scored matches.
     */
    filteredMatches(): MatchModel[] {
      if (this.limit > 0) {
        //todo: add sorting based on last accessed time
        return this.matches.slice(0, this.limit);
      }
      return this.matches;
    },

    /**
     * Returns grouped matches by competition.
     *
     * @return {GroupedMatches}
     */
    matchesGroupedByCompetition(): GroupedMatches {
      const groupedMatches: GroupedMatches = {};

      groupedMatches[UNKNOWN_MATCH_GROUP] = {
        matches: [],
      };

      this.filteredMatches.forEach((match: MatchModel) => {
        const competition = match.competition;

        if (!competition) {
          groupedMatches[UNKNOWN_MATCH_GROUP].matches.push(match);
        } else {
          const group = competition.id;
          const groupName = competition.name;

          if (!groupedMatches[group]) {
            groupedMatches[group] = {
              groupName,
              matches: [],
            };
          }

          groupedMatches[group].matches.push(match);
        }
      });

      return groupedMatches;
    },

    /**
     * get a list of active competitions
     */
    scoredCompetitions(): CompetitionModel[] {
      const competitions: Record<string, CompetitionModel> = {};
      for (let i in this.filteredMatches) {
        if (Object.prototype.hasOwnProperty.call(this.filteredMatches, i)) {
          let competition = this.filteredMatches[i].competition;
          if (competition !== null) {
            if (
              !Object.prototype.hasOwnProperty.call(
                competitions,
                competition.id
              )
            ) {
              competitions[competition.id] = competition;
            }
          }
        }
      }
      return Object.values(competitions);
    },
  },
  created() {
    this.matches = SavedMatches.getMatches();
    SavedMatches.$on("change", () => {
      this.matches = SavedMatches.getMatches();
    });
  },
  methods: {
    /**
     * Remove the selected Scored match
     * @param match
     */
    removeMatch(match: MatchModel) {
      SavedMatches.removeMatch(match);
    },

    /**
     * View the selected match
     * @param match
     */
    onMatchClick(match: MatchModel) {
      this.$router.push({
        name: "app-marker-matches-scorer",
        params: { id: match.id },
      });
    },

    /**
     * Get a collection of matches by their competition Id
     * @param competitionId
     */
    getMatchesByCompetition(competitionId: string): MatchModel[] {
      const matches: MatchModel[] = [];
      for (let i in this.filteredMatches) {
        if (Object.prototype.hasOwnProperty.call(this.filteredMatches, i)) {
          if (
            this.filteredMatches[i].competition !== null &&
            this.filteredMatches[i].competition.id.toString() ===
              competitionId.toString()
          ) {
            matches.push(this.filteredMatches[i]);
          }
        }
      }
      return matches;
    },
  },
});
