






























































import TitleBar from "@/components/TitleBar/index.vue";
import InfoBlock from "@/components/InfoBlock/index.vue";
import Vue from "vue";
import MyScoredMatches from "@/components/MyScoredMatches/index.vue";
import SavedMatches from "@/services/SavedMatches";
export default Vue.extend({
  name: "AppMarkerScoredMatchesController",
  components: {
    MyScoredMatches,
    InfoBlock,
    TitleBar,
  },
  computed: {
    hasScoredMatches(): boolean {
      return SavedMatches.hasMatches();
    },
  },
});
