









import Vue from "vue";

export default Vue.extend({
  name: "InfoBlock",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
});
